import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';
import { useQueryParam, StringParam } from 'use-query-params';

import { Layout } from '../../../components/shared/Layout';
import { Page } from '../../../components/shared/Page';
import { Seo } from '../../../components/shared/Seo';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { FormGroup } from '../../shared/form/FormGroup';
import { Button } from '../../shared/Button';
import { FormPasswordInput } from '../../shared/form/FormPasswordInput';
import { FormGroupError } from '../../shared/form/FormGroupError';
import { password, passwordConfirmation } from '../../../validations';
import { validateAllErrors } from '../../../utilities/validateAllErrors';
import { PasswordStrengthAnalyzer } from '../../shared/form/PasswordStrengthAnalyzer';
import { navigate } from 'gatsby';

const ValidationSchema = object().shape({ password, passwordConfirmation });

const validate = (values) => validateAllErrors(ValidationSchema, values);

export const Password = () => {
  const [token] = useQueryParam('token', StringParam);

  const { newPassword } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);

  const initialFormValues = {
    password: '',
    passwordConfirmation: '',
  };

  const onFormSubmit = async (
    { password },
    { setFieldError, setSubmitting }
  ) => {
    try {
      const data = { password, token };
      await newPassword(data);
      navigate('/ucet/prihlaseni', { state: { passwordChanged: true } });
    } catch (error) {
      if (error.isBadRequest) {
        setSubmitting(false);
        return setFieldError('passwordConfirmation', 'Odkaz už není aktivní');
      }

      if (error.isNotFound) {
        setSubmitting(false);
        return setFieldError('passwordConfirmation', 'Odkaz není platný');
      }

      setGlobalError(error);
    }
  };

  return (
    <Layout showLoginButton={false}>
      <Seo title="Zapomenuté heslo - nové heslo" />
      <Page
        title="Nastavte si nové heslo"
        description="Zadejte nové heslo pro váš účet"
      >
        <Formik
          initialValues={initialFormValues}
          validate={validate}
          onSubmit={onFormSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            isSubmitting,
            isValid,
            errors,
            touched,
            dirty,
          }) => (
            <Form className="flex flex-col sm:max-w-sm mx-auto">
              <FormGroup className="mt-0">
                <FormPasswordInput
                  name="password"
                  placeholder="Nové heslo"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="new-password"
                />
                <PasswordStrengthAnalyzer
                  errors={errors.password}
                  dirty={dirty}
                />
              </FormGroup>

              <FormGroup>
                <FormPasswordInput
                  name="passwordConfirmation"
                  placeholder="Opakovat heslo"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="confirm-password"
                />
                <FormGroupError
                  name="passwordConfirmation"
                  data-testid="error"
                />
              </FormGroup>

              <Button
                type="submit"
                border="normal"
                onClick={handleSubmit}
                disabled={isSubmitting || !dirty || !isValid}
                className="mt-6"
                data-testid="form-reset-password-action-button"
              >
                Uložit heslo
              </Button>
            </Form>
          )}
        </Formik>
      </Page>
    </Layout>
  );
};
